import { GiDigitalTrace } from "react-icons/gi";
import {
  DiCodeigniter,
  DiCss3,
  DiHtml5,
  DiJavascript1,
  DiNodejs,
  DiPhp,
  DiReact,
  DiRedis,
} from "react-icons/di";
import { SiApachekafka, SiExpress, SiKoa, SiTypescript } from "react-icons/si";
import { TbBrandReactNative } from "react-icons/tb";
import { FaGithub, FaGlobe, FaMobileAlt } from "react-icons/fa";

import { ImCloudUpload } from "react-icons/im";
import {
  MdContactPhone,
  MdFlightTakeoff,
  MdLocationOn,
  MdMarkEmailUnread,
} from "react-icons/md";
import { FaDev, FaLinkedin, FaThreads } from "react-icons/fa6";
import { FaMedium } from "react-icons/fa";
import {
  ecommerceSvg,
  FullStackImg,
  markdownParserSvg,
} from "../utils/AllSvgs";

import IdeosLogo from "./../assets/Images/logo/ideos.jpg";
import IglulabLogo from "./../assets/Images/logo/iglulab.png";
import KotakLogo from "./../assets/Images/logo/kotak_logo_png.png";
import XebiaLogo from "./../assets/Images/logo/xebia_logo.jpg";
import InshpereLogo from "./../assets/Images/logo/insphere_logo.jpeg";

import javascriptSvg from "./../assets/Images/svg/javascript.svg";
import awsSvg from "./../assets/Images/svg/aws.svg";
import babeSvg from "./../assets/Images/svg/babel.svg";

import webpackSvg from "./../assets/Images/svg/webpack.svg";
import expressSvg from "./../assets/Images/svg/expressjs.svg";

import reactJsSvg from "./../assets/Images/svg/reactjs.svg";
import lambdaSvg from "./../assets/Images/svg/lambda.svg";
import nodeJsSvg from "./../assets/Images/svg/nodejs.svg";
import reduxSvg from "./../assets/Images/svg/redux.svg";
// Project

import aaEcosystemImage from "./../assets/Images/project/aaEcosystem.jpg";
import calendarNpmPackage from "./../assets/Images/project/react-calendar-npm-package.png";
import ondcImage from "./../assets/Images/project/ondc.jpg";
import BhashiniImage from "./../assets/Images/project/bhashini.webp";
import teleLawImage from "./../assets/Images/project/teleLaw.png";
import ecommerceImage from "./../assets/Images/project/ecommerce.jpg";
import flightBookingImage from "./../assets/Images/project/flightBooking.jpg";
import markdownImage1 from "./../assets/Images/project/markdown.png";

import { darkTheme } from "../utils/Themes";

export const Bio = {
  name: "Kawal Jain",
  shortDescription:
    "Experienced Full Stack Developer in building secure, scalable solutions and leading teams, specializing in e-commerce and tech integrations for regulated industries.",
  description: `I am a skilled Full Stack Developer with extensive experience in building and leading high-performing development teams. Passionate about creating innovative solutions, I thrive on coding and developing cutting-edge features. Whether it's troubleshooting critical issues or architecting new systems, I bring a problem-solving mindset to every challenge. My expertise spans full-stack web development, project management, and driving successful project outcomes.`,
  github: "https://github.com/kawaljain",
  resume:
    "https://drive.google.com/file/d/1JBguSnHX_ma0EFdYEzKF1lHajf6oKfvx/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/kawaljain",
  insta: "https://www.instagram.com/kawal.jain/",

  medium: "https://kawaljain.medium.com/",
  email: "kawaljain03@gmail.com",
  contactCard: [
    {
      id: "contact",
      title: "Contact",
      detail: "9999054013",
      Icon: MdContactPhone,
    },
    {
      id: "email",
      title: "Email",
      detail: "kawaljain03@gmail.com",
      Icon: MdMarkEmailUnread,
    },
    {
      id: "locatiom",
      title: "Location",
      detail: "Delhi",
      Icon: MdLocationOn,
    },
  ],
  services: [
    {
      title: "Javascript",
      description:
        "Solid foundations of vanilla JavaScript, its newest additions (ES6+), superset TypeScript, modern workflow (Babel & Webpack), and testing with Jest.",
      Icon: FaGlobe,
      image: "",
      images: [javascriptSvg, babeSvg, webpackSvg],
    },
    {
      title: "Full Stack with MERN",
      Icon: GiDigitalTrace,
      description:
        "Seasoned builder of fully functioning back-end REST API that can handle business logic, and follows solid principles.",
      images: [nodeJsSvg, expressSvg, reactJsSvg, javascriptSvg],
    },
    {
      title: "React Ecosystem",
      Icon: FaMobileAlt,
      description:
        "Adept at building performant and large-scale apps with React, its latest features (Hooks, Context...) and ecosystem (Redux, React Native, Expo)",
      images: [reactJsSvg, reduxSvg, javascriptSvg],
    },

    {
      title: "Serverless & Cloud",
      description:
        "Familiar with Firebase Auth, Firestore, Functions, and Hosting. Also long-time user of AWS, Google Cloud Engine, for both hosting and proxy tunneling.",
      Icon: ImCloudUpload,
      images: [awsSvg, lambdaSvg],
    },
  ],
  roles: ["Full Stack Developer.", "Team Leader.", "Consultant."],
  skillset: [
    {
      id: "javascript",
      title: "javascript",
      ImageComponent: DiJavascript1,
    },
    {
      id: "nodeJs",
      title: "nodeJs",
      ImageComponent: DiNodejs,
    },
    {
      id: "reactjs",
      title: "reactJs",
      ImageComponent: DiReact,
    },

    {
      id: "expressJs",
      title: "expressJs",
      ImageComponent: SiExpress,
    },
    {
      id: "redis",
      title: "redis",
      ImageComponent: DiRedis,
    },
    {
      id: "kafka",
      title: "kafka",
      ImageComponent: SiApachekafka,
    },
    {
      id: "typesrcript",
      title: "typescript",
      ImageComponent: SiTypescript,
    },
    {
      id: "koa",
      title: "koa",
      ImageComponent: SiKoa,
    },
    {
      id: "php",
      title: "php",
      ImageComponent: DiPhp,
    },
    {
      id: "reactnative",
      title: "reactNative",
      ImageComponent: TbBrandReactNative,
    },
    {
      id: "codeIgniter",
      title: "codeIgniter",
      ImageComponent: DiCodeigniter,
    },
    {
      id: "html",
      title: "html",
      ImageComponent: DiHtml5,
    },
    {
      id: "css",
      title: "css",
      ImageComponent: DiCss3,
    },
  ],
  overView: [
    "⚡ Monitor and analyze performance metrics to identify areas for improvement, ensuring consistent delivery of high-quality services.",
    "⚡ Engage with key stakeholders to understand their needs, gather requirements, and provide technology solutions that align with business goals.",
    "⚡ Define and execute technology strategies that align with organizational goals, driving innovation and ensuring competitive advantage.",
    "⚡ Lead and mentor technical teams, promoting skill development, best practices, and continuous improvement.",
    "⚡ Collaborated with cross-functional teams to define, design, and implement new features that align with client requirements and business goals.",
    "⚡ Technical guidance and mentorship to support the team’s professional development and ensure quality deliverables.",
    "⚡ Establish and enforce standards for code quality, development processes, and project management, maintaining high productivity and quality output.",
  ],
  socialConnect: [
    {
      id: "github",
      title: "github",
      link: "https://github.com/kawaljain",
      Icon: FaGithub,
    },
    {
      id: "linkedIn",
      title: "linkedIn",
      link: "https://www.linkedin.com/in/kawaljain/",
      Icon: FaLinkedin,
    },
    {
      id: "medium",
      title: "medium",
      link: "https://kawaljain.medium.com/",
      Icon: FaMedium,
    },
    {
      id: "dev.to",
      title: "Dev",
      link: "https://dev.to/kawaljain",
      Icon: FaDev,
    },
    {
      id: "threads",
      title: "thread",
      link: "https://www.threads.net/@kawal.jain",
      Icon: FaThreads,
    },
  ],
  blogs: [
    {
      id: "objectProperties",
      title: "Object Properties Explanation",
      description:
        "I am going to explain javascript interview coding question. How javascript compiler works and what it actually produces as output. I’ll break down each part of the output, explain why it appears this way, and connect it back to the specific lines of code responsible",
      img: "",
      tags: [
        "javascript",
        "Object Properties",
        "Interview Question",
        "Javascipt Tips",
      ],
      link: "https://medium.com/@kawaljain03/javascript-interview-question-explanation-object-properties-7c25f060dbd3",
    },
    {
      id: "singletonPattern",
      title: "singleton Design Pattern",
      description:
        "The Singleton Pattern is a design pattern that ensures a class has only one instance and provides a global point of access to it. This single instance is unmodifiable.",
      img: "",
      tags: [
        "Javascript Design Pattern",
        "javascript",
        "Design Pattern",
        "Singleton",
        "Singleton Javascript",
        "Singleton Class",
      ],
      link: "https://medium.com/@kawaljain03/javascript-singleton-design-patterns-8b11c75a3b8e",
    },
    {
      id: "singletonPattern",
      title: "singleton Design Pattern",
      description:
        "The Singleton Pattern is a design pattern that ensures a class has only one instance and provides a global point of access to it. This single instance is unmodifiable.",
      img: "",
      tags: [
        "Javascript Design Pattern",
        "javascript",
        "Design Pattern",
        "Singleton",
        "Singleton Javascript",
        "Singleton Class",
      ],
      link: "https://medium.com/@kawaljain03/javascript-singleton-design-patterns-8b11c75a3b8e",
    },
  ],
};

export const experiences = [
  {
    id: "avp",
    img: KotakLogo,
    role: "Associate Vice President",
    company: "Kotak Mahindra Bank",
    date: "May 2023 - Sept 2024",
    skills: [
      "JavaScript",
      "TypeScript",
      "Node Js",
      "Koa Js",
      "React Js",
      " Next Js",
      "Docker",
      "Kafka",
    ],
    desc: [],
  },
  {
    id: "seniorConsultant",
    img: XebiaLogo,
    role: "Senior Consultant",
    company: "Xebia It Architects India Private Ltd,",
    date: "May 2022 - April 2023",
    skills: [
      "JavaScript",
      "TypeScript",
      "Node Js",
      "Koa Js",
      "React Js",
      " Next Js",
      "Docker",
    ],
    doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/1696514649120.jpeg?alt=media&token=e7f6757b-edfa-4138-a692-d6709eeef3e2",
    d: [
      "Collaborated with cross-functional teams to deliver web applications using React.js (Next.js) and Node.js.",
      "Provided mentorship and technical guidance on React Native projects, improving code quality and efficiency",
      "Played a key role in client meetings, gathering requirements, and providing time estimates, leading to a 20% increase in project approval rates.",
    ],
  },
  {
    id: "fullStackDeveloper",
    img: InshpereLogo,
    role: "Full Stack Developer",
    company: "Insphere Solutions Pvt. Ltd, Delhi",
    date: "Aug 2019 - May 2022",
    skills: [
      "javscript",
      "Node Js",
      "React Js",
      "React Native",
      "php",
      "AWS",
      "Github",
      "Yii2",
    ],
    desc: [
      "Developed and maintained web applications using PHP (Yii Framework), Node.js, React.js, and React Native.",
      "Led a team in delivering projects on time by efficiently coordinating development processes and ensuring adherence to best practices.",
      "Improved application performance by 30% through code optimization and regular code reviews.",
      "Reviewing code to identify issues as well as deviations from best practices",
    ],
  },
  {
    id: "softwareEnginer",
    img: IglulabLogo,
    role: " Software Engineer",
    company: "Iglulabs Pvt. Ltd,",
    date: "Jan 2018 - Dec 2018",
    skills: [
      "javscript",
      "Html",
      "Css",
      "Node Js",
      "AWS",
      "Github",
      "Material UI",
      "Bootstrap 3",
      "php",
      "CodeIgniter",
      "AngularJs",
    ],
    desc: [
      "Worked on full-stack development using PHP, AngularJS, and AWS for deployment",
      "Developed and deployed web applications in live environments, reducing downtime by 15% through effective testing and bug fixing",
    ],
  },
  {
    id: "seniorWebDeveloper",
    img: IdeosLogo,
    role: "Senior Web Developer",
    company: "Ideosinc",
    date: "Oct 2016 - Noc 2017",
    skills: [
      "Html",
      "Css",
      "Bootstrap 3",
      "javscript",
      "php",
      "CodeIgniter",
      "Leadership",
    ],
    desc: [
      "Managed a team for efficient project delivery, ensuring client satisfaction and repeat business.",
      "Designed and developed user-friendly websites using HTML/CSS, JavaScript, and Bootstrap.",
    ],
  },
];

export const ProjectWork = [
  {
    id: "aaEcosystem",
    title: "AA Ecosystem",
    d: "Led the development and management of a Statement Analyzer tool, handling onboarding, report generation, and management of FIP and user consent.",
    shortDesc: [
      "⚡ Led the development and management of the Statement Analyzer tool, powered by Perfios. This tool was responsible for analyzing financial statements, ensuring accurate data processing and reporting",
      "⚡ Look for full ownership of the Admin Management system, which oversees critical tasks including report generation, onboarding of internal products like Personal Loans (PL) and Business Loans (BL), and managing Financial Information Providers (FIP) and user consent. Ensured smooth and efficient management of all admin-related functions.",
      "⚡ Created and implemented internal packages to streamline the integration of Account Aggregator (AA) services, enhancing the efficiency of financial data management.",
      "⚡ Developed a specialized package responsible for the detailed analysis of financial statements, improving accuracy and providing actionable insights",
    ],

    description: [
      "⚡ Led the development and management of the Statement Analyzer tool, powered by Perfios. This tool was responsible for analyzing financial statements, ensuring accurate data processing and reporting",
      "⚡ Look for full ownership of the Admin Management system, which oversees critical tasks including report generation, onboarding of internal products like Personal Loans (PL) and Business Loans (BL), and managing Financial Information Providers (FIP) and user consent. Ensured smooth and efficient management of all admin-related functions.",
      "⚡ Created and implemented internal packages to streamline the integration of Account Aggregator (AA) services, enhancing the efficiency of financial data management.",
      "⚡ Developed a specialized package responsible for the detailed analysis of financial statements, improving accuracy and providing actionable insights",
    ],
    skillSet: ["ReactJs", "koaJs", "Kafka", "Mongo", "nodeJs"],
    Svg: aaEcosystemImage,
    img: aaEcosystemImage,
    isStatic: true,
    isOpenSource: true,
    isLive: true,
    live: null,
    github: null,
    showMainPage: true,
  },
  {
    id: "react-infinite-scroll-calendar",
    title: "React Infinite Scroll Calendar",
    d: "The React Infinite Scroll Calendar is an open-source npm package that provides a smooth, infinite scrolling calendar component for React and typesript.",
    shortDesc: [
      "⚡ Led the development and management of the Statement Analyzer tool, powered by Perfios. This tool was responsible for analyzing financial statements, ensuring accurate data processing and reporting",
      "⚡ Look for full ownership of the Admin Management system, which oversees critical tasks including report generation, onboarding of internal products like Personal Loans (PL) and Business Loans (BL), and managing Financial Information Providers (FIP) and user consent. Ensured smooth and efficient management of all admin-related functions.",
      "⚡ Created and implemented internal packages to streamline the integration of Account Aggregator (AA) services, enhancing the efficiency of financial data management.",
      "⚡ Developed a specialized package responsible for the detailed analysis of financial statements, improving accuracy and providing actionable insights",
    ],

    description: [
      "⚡ Led the development and management of the Statement Analyzer tool, powered by Perfios. This tool was responsible for analyzing financial statements, ensuring accurate data processing and reporting",
      "⚡ Look for full ownership of the Admin Management system, which oversees critical tasks including report generation, onboarding of internal products like Personal Loans (PL) and Business Loans (BL), and managing Financial Information Providers (FIP) and user consent. Ensured smooth and efficient management of all admin-related functions.",
      "⚡ Created and implemented internal packages to streamline the integration of Account Aggregator (AA) services, enhancing the efficiency of financial data management.",
      "⚡ Developed a specialized package responsible for the detailed analysis of financial statements, improving accuracy and providing actionable insights",
    ],
    skillSet: ["ReactJs", "javascript", "Typesript", "Npm Package", "Github"],
    Svg: calendarNpmPackage,
    img: calendarNpmPackage,
    isStatic: true,
    isOpenSource: true,
    isLive: true,
    live: "https://www.npmjs.com/package/@anvikjs/react-infinite-scroll-calendar",
    github: "https://github.com/anvikjs/react-infinite-scroll-calendar",
    showMainPage: true,
  },
  {
    id: "markdownParser",
    title: "Markdown Parser",
    d: "Designed a high-performance, modular parser for transforming Markdown into HTML. Developed core parsing logic, including single-pass tokenization for speed and efficiency.",
    shortDesc: [
      "Designed a high-performance, modular parser for transforming Markdown into HTML.",
      "Developed core parsing logic, including single-pass tokenization for speed and efficiency.",
      "Led an open-source project with community engagement, managing contributions, and providing comprehensive documentation for developers.",
    ],
    description: [
      "⚡ Designed the core structure of the parser, focusing on modularity and extensibility.",
      "⚡ Wrote the core parsing logic, including the tokenizer and the conversion algorithms for transforming Markdown syntax into HTML.",
      "⚡ Focused on optimizing the parser for speed by implementing single-pass tokenization and minimizing redundant operations",
      "⚡ Led the open-source effort, maintaining the project’s repository, managing contributions, and reviewing pull requests from the community.",
      "⚡ Wrote detailed documentation to guide other developers in using and contributing to the project.",
    ],
    skillSet: ["ReactJs", "javascript", "Regex"],
    Svg: markdownParserSvg,
    isOpenSource: true,
    live: "https://markdown.kawaljain.com/",
    github: "https://github.com/kawaljain/markdown-parser",
    img: markdownImage1,
    showMainPage: true,
    isStatic: true,
  },

  {
    id: "kaymall",
    title: "Kaymall ONDC",
    d: "Led the development of a responsive frontend application using React.js and Next.js, ensuring a seamless user experience across devices.",
    shortDesc: [
      "⚡ Designed the core structure of the parser, focusing on modularity and extensibility.",
      "⚡ Wrote the core parsing logic, including the tokenizer and the conversion algorithms for transforming Markdown syntax into HTML.",
      "⚡ Focused on optimizing the parser for speed by implementing single-pass tokenization and minimizing redundant operations",
      "⚡ Led the open-source effort, maintaining the project’s repository, managing contributions, and reviewing pull requests from the community.",
      "⚡ Wrote detailed documentation to guide other developers in using and contributing to the project.",
    ],

    description: [
      "⚡ Designed the core structure of the parser, focusing on modularity and extensibility.",
      "⚡ Wrote the core parsing logic, including the tokenizer and the conversion algorithms for transforming Markdown syntax into HTML.",
      "⚡ Focused on optimizing the parser for speed by implementing single-pass tokenization and minimizing redundant operations",
      "⚡ Led the open-source effort, maintaining the project’s repository, managing contributions, and reviewing pull requests from the community.",
      "⚡ Wrote detailed documentation to guide other developers in using and contributing to the project.",
    ],
    skillSet: ["ReactJs", "javascript", "nodeJs", "kafka", "Mongo"],
    Svg: FullStackImg,
    img: ondcImage,
    isStatic: true,
    isOpenSource: true,

    live: null,
    github: null,
    showMainPage: true,
  },
  {
    id: "flightBooking",
    title: "Kaymall Flight Booking",
    d: "Developed a microservices architecture for operations, Travel Booking Operations (TBO) APIs, and refunds, enhancing scalability and reliability. ",
    shortDesc: [],

    description: [],
    skillSet: ["ReactJs", "koaJs", "Kafka", "Mongo", "nodeJs"],
    Svg: MdFlightTakeoff,
    svgConfig: {
      color: darkTheme.primary,
    },
    isOpenSource: false,
    isLive: true,
    live: null,
    github: null,
    showMainPage: false,
    isStatic: true,
    img: flightBookingImage,
  },
  {
    id: "bhashaDaan",
    title: "Bhashini/Bhashadaan",
    d: "Led the development of Bhashini/Bhashadaan, a multilingual platform that provides Indians access to content in native languages, using Joomla for localization. ",
    shortDesc: [],

    description: [],
    skillSet: ["ReactJs", "node", "javascipt", "php", "aws", "Joomla", "php"],
    Svg: FullStackImg,
    isOpenSource: true,
    isLive: true,
    live: null,
    github: null,
    isStatic: true,
    img: BhashiniImage,
    showMainPage: true,
  },
  {
    id: "grievanceManagement",
    title: "Grievance Management",
    d: "Spearheaded the development of a Yii2-based Grievance Management System for NIOS, featuring authentication, grievance submission, inter-departmental transfer, resolution tracking, and reporting. This system improved user experience and administrative efficiency, enabling faster grievance resolutions.",
    shortDesc: [],
    description: [],
    skillSet: ["nodeJs", "php", "aws", "mysql"],
    Svg: FullStackImg,
    isOpenSource: false,
    isLive: true,
    live: null,
    github: null,
    img: teleLawImage,
    showMainPage: false,
  },
  {
    id: "telelaw",
    title: "Telelaw",
    d: "Led the development of 'Tele Law,' a legal advisory platform built with Yii2 and PHP, enabling real-time video conferencing between lawyers and applicants. ",
    shortDesc: [],
    description: [],
    skillSet: ["ReactJs", "nodeJs", "php", "React Native", "AWS", "mySql"],
    Svg: FullStackImg,
    isOpenSource: false,
    isLive: true,
    live: null,
    github: null,
    isStatic: true,
    img: teleLawImage,
    showMainPage: true,
  },
  {
    id: "ecommerce",
    title: "E-Commerce & CMS",
    d: "Led cross-functional teams to deliver high-quality e-commerce solutions on time and within budget. Achieved a 30% improvement in application performance through code optimization and regular reviews. Played a key role in client meetings, ensuring alignment with business objectives.",
    shortDesc: [],
    description: [],
    skillSet: ["php", "React Natvie", "Aws", "Mysqli"],
    Svg: ecommerceSvg,
    isOpenSource: false,
    isLive: true,
    live: null,
    github: null,
    isStatic: false,
    img: ecommerceImage,
    showMainPage: false,
  },
];
